import request from '@/utils/request'
import ENDPOINT from '../config/endpoint'

export function createHierarchy(data) {
  return request({
    url: ENDPOINT.HIERARCHY,
    method: 'post',
    data
  })
}

export function updateHierarchy(data) {
  return request({
    url: ENDPOINT.HIERARCHY,
    method: 'patch',
    data
  })
}

export function getHierarchy(params) {
  return request({
    url: ENDPOINT.HIERARCHY,
    method: 'get',
    params
  })
}

export function deleteHierarchy(params) {
  return request({
    url: ENDPOINT.HIERARCHY,
    method: 'delete',
    params
  })
}
